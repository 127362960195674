// .container.details { max-width: 950px; }
main {
.container { margin: 35px auto 53px; }
.details {
//  & { min-height: 625px; max-width: 865px; width: 95%; background: #FFFFFFF2; border-radius: 10px; margin-top: 45px; }
  & { min-height: 750px; max-width: 1361px; width: 95%; background: #FFFFFF; border-radius: 10px; margin-top: 45px;  display: flex; justify-content: center; align-items: center; position : relative}
  .details-container{max-width: 865px;  margin: 0 auto; background: #FFFFFFF2;}
    h1 { width: auto; font-weight: 700;  line-height: 34px; text-align: center; margin: 0 auto; font-family: $fontLato; font-size: 32px; color: #555555; padding: 32px 15px 0; }
    p { text-align: center; font-size: 21px; font-weight: 400; max-width: 80ch; line-height: 34px; color: #343333; padding: 0 15px; margin: 15px auto; font-weight: 700; }
    .heading { text-align: center; font-size: 32px;  max-width: 80ch; line-height: 34px; color: #555555; padding: 0 15px; margin: 15px auto; font-weight: 700; font-family: $primaryfont; }
    // .sub-heading { font-size: 21px; font-style: normal; font-weight: 400; line-height: 34px; font-family: $primaryfont; }
    .sub-heading { font-size: 21px; font-style: normal; font-weight: 400; line-height: 34px; font-family: $primaryfont;padding-top: 12px; color: #555555; }

    .form {
      & { display: flex; justify-content: center; flex-wrap: wrap; margin: 30px auto 0px auto; width: 68%; }
      .form-detail { 
        * { box-sizing: border-box; border: none; }
        & { position: relative; flex: 0 1 46%; max-width: 46%; display: block; margin-bottom: 20px; font-weight: 700; padding: 0 2%; /* overflow: hidden; */ }
        p { padding: 0; margin-bottom: 3px; text-align: left; font-size: 16px; font-weight: 700; line-height: 19px; color: #555555; }
        .MuiInput-root { font-family: $primaryfont !important; font-size: 18px; font-weight: 400; }
        .MuiInput-input { height: 28px; font-weight: 400; font-size: 18px; color: #343333; }
        .MuiInput-input::placeholder { font-weight: 400; color: #868686; opacity: 1 !important; font-size: 18px; color: #9B9B9B; }
        .MuiSelect-select .first-menu { color: #fff; }
        
        // .form-details-text { background-color: #F4F4F4; padding: 15.6px 15px; padding: 10px 15px; max-width: 100%; }
        // .form-details-text { background-color: #F4F4F4; max-width: 100%; border-radius: 3px; }
        .form-details-text { background-color: #F4F4F4; margin-top: 5px; max-width: 100%; border-radius: 3px; border: none; min-width:250px }
        .form-details-text .MuiInput-root { padding: 9px 10px; border-radius: 3px; border: 1px solid transparent; }
        .form-details-text .MuiInput-root.Mui-focused { border: 1px solid #343333; box-shadow: 0 0 0 1px #343333; }
        
        
        // .form-details-text.react-select div:nth-child(3) { border: 1px solid #ccc; border-radius: 20px; }
        // .form-details-text.react-select div:nth-child(6) { border: 1px solid #ccc; border-radius: 20px; }
        .form-details-text .react-select__control--is-focused { border: 1px solid #343333; box-shadow: 0 0 0 1px #343333; } 
        .form-details-text.react-select .react-select__value-container { padding: 7px 10px; border-radius: 3px; }
        .form-details-text.react-select .react-select__indicator-separator { display: none; }
        .form-details-text .react-select__single-value { font-weight: 400; }
        .form-details-text.react-select .react-select__placeholder { font-family: $fontLato; font-size: 18px; white-space: nowrap; overflow-x: hidden; color: #9B9B9B; font-weight: 400; line-height: 21.6px; }
        .form-details-text.react-select { & { padding: 0; font-size: 18px; font-family: $primaryfont; font-weight: 400; margin-top: 10px; font-family: $fontLato; }
          // div:first-child { padding-top: 6.25px; padding-bottom: 6.25px; }
        }

        .form-details-text input:focus::placeholder { color: transparent; font-weight: 300; }
        .form-details-text {
          input[type=number]::-webkit-inner-spin-button, 
          input[type=number]::-webkit-outer-spin-button { 
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
          }
        }
        // .error-text { position: absolute; bottom: -20px; color: red; font-weight: 400; font-size: 13px; }
        .error-text { position: initial; color: #E25B5B; font-weight: 400; font-size: 12px; line-height: 14px; margin-top: 3px; }
        .required-field { color: #EC1C24; font-size: 22px; padding-left: 0px; }
        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus, 
        input:-webkit-autofill:active{
            -webkit-box-shadow: 0 0 0 30px #F4F4F4 inset !important;
        }
      }
      .form-detail:last-child.school-name {
        flex: 0 1 100%; max-width: 100%; width: 100%;
      }
      }

    .details-buttons { & { max-width: 80%; margin: 50px auto; padding-top: 1rem; padding-bottom: 3rem; } 
      .back-btn {
        // & { padding: 0; max-width: 50px; width: 50px; min-width: 50px; border-radius: 50%; }
        & { padding: 0; max-width: 50px; width: 50px; min-width: 50px; border-radius: 50%; position: absolute;left : 10%; bottom: 10%; transform: translateY(-50%);padding: 0; max-width: 50px; width: 50px; min-width: 50px; border-radius: 50%; }
        & img { width: auto; height: 100%; max-height: 50px; }
        button { font-family: $primaryfont; font-size: 18px; color: #444444; padding: 10px 15px; }
      }
      .cta-btn {
        & { margin: 0 auto; text-decoration: none; align-self: center; width: 100%; display: flex; align-items: center; justify-content: center; }
        a { text-decoration: none; }
        // button.Mui-disabled { background-color: #D8D8D8; color: #FFFFFF; }
        button.Mui-disabled { background-color: #D8D8D8; border-radius: 30px; color: #555555; }
        button { font-size: 21px; font-family: $primaryfont; display: block; background-color:#99BB00;  border-radius: 30px; color: #fff; text-decoration: none;  height: 50px; text-align: center; font-size: 21px; font-weight: 700; line-height: 19px; text-transform: uppercase; padding: 7px 15px; margin-left: 0; transition: 0.3s; width: 100%; max-width: 275px; }
        button:hover { background: #4C5454; transition: 0.3s; color: #fff;}
      .button-progress { margin: 4px 0 -2px; }
    }
    .cta-btn-active {
      & { margin: 0 auto; text-decoration: none; align-self: center; width: 100%; max-width: 275px; }
      a { text-decoration: none; }
      button.Mui-disabled { background-color: #D8D8D8; color: #979797; }
        button { font-family: $primaryfont; display: block; background-color: #99BB00; border-radius: 30px; color: #fff; text-decoration: none;  height: 50px; text-align: center; font-size: 21px; font-weight: 700; line-height: 19px; text-transform: uppercase; padding: 7px 15px; margin-left: 0; transition: 0.3s; width: 100%; max-width: 275px; }
        button:hover { background: #4C5454; transition: 0.3s; color: #fff;}
      .button-progress { margin: 4px 0 -2px; }
    }
  }
  .back-btn { & { align-self: center; } button { color: #444444; padding: 10px 15px; }
    button { font-family: $primaryfont; font-size: 18px; }
  }
  .bottom { width: 100%; max-width: 90%; margin: 30px auto; }
}

.details-container { & { padding: 40px 15px 0; background-color: #FFFFFF; border-radius: 10px; }  
  .overall-score-subtext {
    & { width: auto; max-width: 90%; box-sizing: border-box; margin: auto; margin-bottom: 20px; margin-bottom: 50px; margin-top: 25px; }
    p { & { margin-top: 0; font-size: 21px; font-weight: 400; line-height: 28px; letter-spacing: 0px; text-align: center; color: $textcolor1; }
      span{ font-weight: bold; }
    }
  }
}

.details-container .additional-questions-container { & { width: 96%; max-width: 840px; margin: 45px auto 30px; }
  h2 {  }
  h3 {  }
  .answers { background-color: #F4F4F4; border-radius: 10px; padding: 15px; }
  .answers.last { background-color: #FFF; }
  .answers.last.just-slider { & { padding: 5px 15px; }
    .urgency h2 { margin: 5px auto; }
    .urgency .urgency-slider { padding: 15px 30px; padding-top: 15px !important; }
  }
  .answers-list { & { padding: 10px 10px 10px 35px; margin: 0 auto; }
    li { font-size: 18px; line-height: 24px; margin: 7px auto; }
  }
}
}

@media only screen and (max-width: 550px) {
  .details { & { width: 95%; margin: 15px auto; border-radius: 0px; }
    h1, main .pre-additional-questions-details h1 { font-size: 24px; margin: 0px; line-height: 38px; padding: 24px 9px 0px 8px; color: #000; }
    p { padding: 0; font-size: 18px; line-height: 26px; text-align: center;  max-width: 319px; font-weight: 400; padding: 0px 9px 0px 11px;  margin-bottom: 0px; }
    p br { content: ""; }
    p br:after { content: " "; }
    .form, main .pre-additional-questions-details .form { margin: 35px auto 0px; }
    .form .form-detail .form-details-text { padding-right: 0; width: 100%; min-height: 45px; }
      .details-buttons { & { width: 100%; max-width: 90%; padding-bottom: 0px ; }
      // .back-btn { align-self: flex-start; margin-top: 2rem; }
        .back-btn {
          display: none;
        }
        // .cta-btn { & { text-align: center; min-width: 250px; font-size: 21px; }
        .cta-btn { & { text-align: center; min-width: 250px; font-size: 18px;}
          button { min-width: 250px; max-width: 280px; padding: 12px 15px; color: #FFF; text-align: center; font-family: $primaryfont; font-size: 21px; font-style: normal; font-weight: 700; line-height: normal; letter-spacing: 1px; text-transform: uppercase; background-color: #7CCEE7; } }
    }
  }

  .details .form .form-detail .form-details-text { background-color: #F4F4F4; }
  // .details .form .form-detail .form-details-text.react-select { max-width: 97.5%; }
  main .details .details-buttons { 
    & { padding-bottom: 0px; }
  }

  .details .form .form-detail .MuiInput-input { height: 22px; color: #343333;  font-size: 18px; font-style: normal;  font-weight: 600;  line-height: normal; }
  main .details-container { display: flex; flex-direction: column; justify-content:center; align-items: center; padding: 0px 4px 0px; }

}


@media only screen and (max-width: 650px) {
  .details-container .overall-score-subtext { 
    & { padding: 12px 2px; border-radius: 10px; box-sizing: border-box; max-width: 100%; }
    p { margin: 5px auto; font-size: 18px; line-height: 28px; }
  }
  .details .details-container .heading { font-size: 21px; font-weight: 700; line-height: 34px; letter-spacing: 0px; text-align: center; padding: 0px; max-width: 25ch; }
  .details .details-container .sub-heading { font-size: 16px; line-height: 25px; font-weight: 400; padding: 0; max-width: 31ch;  }
  .details h1, .pre-additional-questions-details h1 { color: #000; text-align: center; font-family: $primaryfont; font-size: 24px; font-style: normal; font-weight: 700; line-height: 38px; }
  .details-container .additional-questions-container { & { width: 96%; }
    h2 {  }
    h3 { text-align: center; }
    .answers { background-color: #F4F4F4; border-radius: 10px; padding: 10px; }
    .answers-list { & { padding: 10px 10px 10px 25px; }
      li { font-size: 16px; }
    }
  }
  .details, .pre-additional-questions-details {
    .form { & { width: 90%; } 
      .form-detail { & { flex: 0 1 100%; max-width: 100%; margin-bottom: 24px; }
        .input-title { max-width: 100%; font-size: 16px; font-weight: 700; color: #202945; margin: 0px 0px 5px 0px; line-height: 19.2px; }
        .error-text { margin: 0 auto -10px; }
      }
    }
  }
  .details .form .form-detail .required-field { color: #EA1C22; font-size: 16px; font-style: normal; font-weight: 700; line-height: normal; }
  main .details .details-buttons .cta-btn button { font-family: $fontLato; font-size: 21px; font-weight: 700; line-height: 25.2px; letter-spacing: 1px; text-align: center; white-space: nowrap; }

}



@media only screen and (max-width: 830px) {
  .details .form, .pre-additional-questions-details .form { width: 85%; }

}




.details-container { 
  & { padding: 15px 15px 15px; }
  .include-results-confirmation {
    & { display: flex; gap: 5px; align-items: center; justify-content: center; margin: 7px auto; }
    input { width: 15px; height: 15px; border: 1px solid #979797; }
    label { display: flex; justify-content: center; align-items: center; font-weight: 400; font-size: 14px; line-height: 18.58px; color: #080808; cursor: pointer; }
  }
}

