.explainer {
  .container { background-color: white; border-radius: 10px; max-width: 1248px; min-height: 680px; 
    .container-flex{ padding: 150px 48px 48px; display: flex; justify-content: center; margin: 30px auto 0;  gap: 43px; }
      .left-box { width: 40%; display: flex; justify-content: center; align-items: center; padding-right: 0px;
        .grid-container { display: grid; grid-template-columns: repeat(3, 1fr); gap: 10px;
          .grid-item { background-color: rgba(0, 97, 177, 0.7);  color: white; padding: 15px; border-radius: 5px;font-family: $fontLato; text-align: center; font-weight: 700; font-size: 21px; aspect-ratio: 1 / 1; display: flex; justify-content: center; align-items: center; width: 130px; height: 110px; cursor: default; }
            }
      }
      .right-content { width: 50%; display: flex; flex-direction: column; justify-content: center; align-items: flex-start; padding-left: 20px; font-family: $fontLato; cursor: default;
        
        p { color: #555555; font-family: $primaryfont; font-size: 21px; font-weight: 400; line-height: 36px; text-align: left; }
          .begin-button { background-color: #99BB00; color: #FFFFFF; border: none; padding: 10px 35px; border-radius: 30px; cursor: pointer; font-family: $primaryfont; font-weight: 700; font-size: 24px; line-height: 28.8px; margin: 16px 0; }
          .begin-button:hover { background-color: #4C5454; }
          .first { & { color: #555555; margin-bottom: 24px; font-size: 30px; font-weight: 700;line-height: 28.8px;text-align: left; word-spacing: 4px; line-height: 36px; }
          .right-content-br { display: none; } 
          }
          .highlight { color: #99BB00; font-weight: 700;}
          .note { 
            & { font-size: 21px; color: #555555; margin-top: 20px; font-family: $primaryfont; font-weight: 400; line-height: 36px; }
            span { color: #555555; font-weight: 700; }
          }
      }
    .explainer-button { padding: 0 50px 40px; }  
  }
}

@media only screen and (max-width: 1200px) {
.explainer .container .container-flex { 
    &{ width: 80%; justify-content: center; gap : 70px; }
        .left-box .grid-container .grid-item { width: 100px; height: 85px; font-size: 12px; }
        .right-content { width: 40%; font-family: $fontLato; }
    }
}
  
@media only screen and (max-width: 830px) {
    .explainer .container .container-flex { 
        &{ flex-direction: column; align-items: center; gap: 40px; width: 100%;  margin-left: auto;  margin-right: auto; padding: 20px 1px; }
            .left-box, .right-content { width: 100%; padding: 0; }
            .left-box .grid-container .grid-item { width: 90px; height: 85px; font-size: 12px; }
            .right-content { align-items: center; text-align: center; font-family: $fontLato; width: 99%; margin: 0 auto;
                p { font-size: 18px; }
                .begin-button { font-size: 24pxpx; font-weight: 700;}
                .note { font-size: 16px; }
            }
    }
}
  
@media only screen and (max-width: 650px) {
    .explainer .container {
        .left-box .grid-container .grid-item { width: 80px; height: 70px; font-size: 11px; padding: 10px; }
        .right-content { font-family: $fontLato;
            p { font-size: 16px; }
            .begin-button { font-size: 15px; }
            .note { font-size: 14px; }
        }
    }
}
  
@media only screen and (max-width: 550px) {
    .explainer .container {
            .left-box .grid-container .grid-item { width: 80px; height: 80px; font-size: 9px; padding: 7px; font-size: 12px; font-weight: 700; }
            .right-content { font-family: $fontLato;
                p { font-size: 14px; }
                .first { text-align: center; }
                .begin-button { font-size: 14px; padding: 8px 20px; }
                .note { font-size: 12px; }
            }
    }
    .explainer .container .right-content .first { font-size: 18px; font-weight: 700; line-height: 23px; word-spacing: 2px; }
    .explainer .container .right-content .first .right-content-br { display: block; }
    .explainer .container .left-box .grid-container { gap: 5px; }
    .explainer .container .right-content .specification { font-size: 16px; line-height: 23px; text-align: center; font-family: $primaryfont; width: 90%; font-size: 15px !important; }
    .begin-button { background-color: #99BB00; color: #FFFFFF; border: none; padding: 10px 30px; border-radius: 30px; cursor: pointer; font-family: $primaryfont; font-weight: 700; font-size: 18px; line-height: 28.8px; margin: 16px 0; }
    .begin-button:hover { background-color: #4C5454; }
    .explainer .container .submit-container { display: flex; flex-direction: column; align-items: center; margin-bottom: 50px; }
    .explainer .container .submit-container .note{ font-size: 16px; font-weight: 400; color: #555555; text-align: center; max-width: 30ch; }
    .explainer .container .explainer-button { margin: -40px auto 0; width: 322px; padding: 0 0 50px; }
    .margin-left { margin-left: -14px; }
    
}
@media only screen and (min-width: 344px) and (max-width: 395px) {
  .explainer .container .container-flex .right-content p { font-size: 15px !important; }
}