
$color3: #343333;

// header{background-color:"#292829"}

main{

.results {
    & { min-height: 630px; background: #fff; color: $color3; width: 96%; margin: 30px auto 30px !important;padding: 50px 0; padding-bottom: 0; }
    .spacer { height: 20px; }
    .selection-form {
      & { width: 50%; display: flex; align-items: center; margin: 20px auto; flex-wrap: wrap; justify-content: center; text-align: center; font-size: 36px; font-weight: 400; }
      .form-detail { width: 100%; max-width: 500px; }
      .form-text { flex: 0 1 100%; font-size: 36px; }
      .form-details-text { 
        & { border: 0px solid #efefef; border-bottom: 4px solid #004F88; padding: 3px 7px; max-width: 90%; }
        .MuiInput-root { font-size: 34px; font-family: $primaryfont; gap: 10px; }
      }
    }

    h1 { &{ width: auto; text-align: center; margin: 0 auto; font-family: $primaryfont; font-size: 30px;  line-height: 38px; color: #555555;   padding: 0px 20px 30px; max-width: 45ch; font-weight: 700;}
      .user-name { text-transform: capitalize; }
    }
    .down-arrow { height: 25px; cursor: pointer; position: absolute; right: 10px; }
    .single-group_assessment #group-sections .si_ass_result { padding: 7% 0px 3%; }
    span.msi_name { color: $color1; font-size: 18px; font-weight: 900; font-family: $primaryfont; }
    .pro_top_details span { display: block; line-height: 30px; min-width: 180px; }
    .pro_top_details{ position: absolute; bottom: 45px; left: 0; max-width: 130px; width:100%; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .in-ass-result .si_ass_progress span, .single-assessment .si_ass_progress span { position: absolute; font-size: 30px; bottom: 42px; left: 0; font-weight: bold; color: #4A4A4A; font-family: $primaryfont; }
    .si_ass_progress:before {  }
    .single-group_assessment #group-sections .si_ass_progress:before { height: 45px; }

    .si_ass_progress { position: relative; max-width: 700px; height: 26px; margin: 0 auto; border-radius: 12px; width: 100%; 
      background-image: linear-gradient(to right, #cc2c24, #ffca26 62%, #429653 100%); 
      // background-image: linear-gradient(to right, #cc2c24 69.5%, #E98526 70% 79.5%, #FFCA27 80% 94.5%, #4E9A51 95% 100%);
    }
    .si_ass_progress .progress-line { z-index: 2; width: 2px; height: 40px; content: ' '; background: $color3; left: 0; bottom: 0; position: absolute; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .si_ass_progress .progress-line .progress-text { position: absolute; top: -65px; left: -100px; width: 200px; font-size: 18px; color: $color3; height: 30px; text-align: center; }
    .si_ass_progress .msi_score { z-index: 2; background-color: #004F88; border-radius: 8px; padding: 5px 5px; display: block; position: absolute; font-size: 27px; font-weight: 700; color: #fff; bottom: 50px; left: 0; margin-left: -30px; min-width: 45px; text-align: center; }
    .si_ass_progress .score-arrow { z-index: 1; width: 20px; height: 20px; content: ' '; background-color: #004F88; left: 0; bottom: 45px; position: absolute; margin-left: -10px; transform: rotate(-45deg); }
    .si_ass_progress .transparent { background-color: #ffffffbb; height: 100%; margin-left: auto; border-top-right-radius: 12px; border-bottom-right-radius: 12px;}
    
    // .si_ass_progress .msi_score::after { display: block; margin: 0 33% 1rem 33%; content: ""; height: 50px; background-color: #0E435C; width: 2px; color: #ffffff; }

    .pre-score-header { line-height: 28px; text-align: center; color: #555555; font-family: $primaryfont; font-size: 21px; font-weight: 400; margin: auto auto; max-width: 44ch; }
    
    .result-sections { 
      & { margin: 50px 5%; display: flex; justify-content: center; flex-wrap: wrap; padding-bottom: 50px; }
    }
    
    .selection-form .form-details-text .MuiInput-root .MuiSelect-select { padding-right: 0; font-size: 36px; }
    //.css-eglki6-MuiLinearProgress-root { background: #E9E9E9; height: 5px; }
    //.css-5xe99f-MuiLinearProgress-bar1 { background: $color1; height: 5px; }

    .members-answered { & { width: 96%; max-width: 777px; background: #FBFBFB; margin: 20px auto; padding: 0px 0% 0px; font-family: $primaryfont; font-size: 18px; color: #1f2a44; text-align: left; border-radius: 8px; }
      .section {
        & { background-color: #0E435C; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 3px;  border-top-right-radius: 3px; display: flex; align-items: center; justify-content: space-between; cursor: pointer; }
        .section-toggle { display: block; cursor: pointer; height: 24px; }
      }
      .answered-detail { 
        & { font-weight: 400; font-size: 18px; color: $color3; padding: 10px 30px; width: calc(100% - 60px); display: flex; justify-content: space-between; align-items: center; }        
        .answer { margin-left: 10px; text-align: right; font-weight: 700; min-width: 18ch; max-width: 20ch; text-transform: uppercase; font-size: 14px; }
        p { margin: 0; }
        .true { color: #73B76F; }
        .neutral { color: #FFB545; }
        .false { color: #FF6645; }
      }
      .answered-detail:nth-child(even) { background-color: #f4f4f4; }      
    }
  }
.results {
  .progress-bar { & { margin: 60px auto 5px; max-width: 90%; display: block; }}
    .si_pro_header { max-width: 700px; margin: 10px auto 0; display: flex; clear: both; position: relative; bottom: 88px; }
    .si_pro_header h4 { position: relative; font-size: 16px;  font-weight: 400; text-align: center; color:  $color3; text-transform: capitalize; margin-top: 10px; }
    .si_pro_header h4.nimp:before { width: 2px; height: 28px; content: ' '; background: #fff; position: absolute; right: 0; }
    .si_pro_header h4.mexpe:before { width: 2px; height: 28px; content: ' '; background: #fff; position: absolute; right: -2px; }
    .si_pro_header h4.eexpe:before { width: 2px; height: 28px; content: ' '; background: #fff; position: absolute; right: -2px; }
    .si_pro_header h4.eexpe1:before { width: 2px; height: 28px; content: ' '; background: #fff; position: absolute; right: 0; }
    .si_pro_header h4 span { color: $color1; line-height: 35px; font-size: 16px; }

    .pervalue h4 { position: relative; }
    .pervalue { max-width: 700px; margin: -68px auto; margin: 0 auto; display: flex; clear: both; color: #4A4A4A; font-size: 15px; position: relative; top: -70px; }
    // .pervalue h4.nimp1:after { content: '0%'; position: absolute; right: -19px; bottom: 35px; }
    // .pervalue h4.nimp:after { content: '45%'; position: absolute; right: -19px; bottom: 35px; }
    // .pervalue h4.mexpe:after { content: '70%'; position: absolute; right: -19px; bottom: 35px; }
    // .pervalue h4.eexpe:after { content: '100%'; position: absolute; right: -19px; bottom: 35px; }
    .pervalue h4.nimp1:after { content: "0%"; position: absolute; right: -19px; font-weight: 800; top: 55px; font-family: $fontLato; font-weight: 700; font-size: 14px; color: #555555; line-height: 16.8px; }
    .pervalue h4.nimp:after { content: "50%"; position: absolute; right: -19px;  font-weight: 800; top: 55px; font-family: $fontLato; font-weight: 700; font-size: 14px; color: #555555; line-height: 16.8px; }
    .pervalue h4.mexpe:after { content: "80%"; position: absolute; right: -19px;  font-weight: 800; top: 55px; font-family: $fontLato; font-weight: 700; font-size: 14px; color: #555555; line-height: 16.8px; }
    .pervalue h4.eexpe:after { content: "100%"; position: absolute; right: -10px; font-weight: 800; top: 55px; font-family: $fontLato; font-weight: 700; font-size: 14px; color: #555555; line-height: 16.8px; }
    .pervalue h4.eexpe1:after { content: "70%"; position: absolute; right: -19px; font-weight: 800; top: 55px; font-family: $fontLato; font-weight: 700; font-size: 14px; color: #555555; line-height: 16.8px; }
    .pervalue h4.eexpe2:after { content: "100%"; position: absolute; right: -19px; font-weight: 800; top: 55px; font-family: $fontLato; font-weight: 700; font-size: 14px; color: #555555; line-height: 16.8px; }
    // .pervalue-text h4.nimp:after { content: 'Not Aligned'; position: absolute; right: -19px; bottom: -75px; }
    // .pervalue-text h4.mexpe:after { content: 'Needs Better Alignment'; position: absolute; right: -19px; bottom: -75px; }
    // .pervalue-text h4.eexpe:after { content: 'Aligned'; position: absolute; right: -19px; bottom: -75px; }
    .pervalue-text { & { position: relative; display: flex; margin: 0 auto; width: 100%; max-width: 700px; bottom: 70px; }
      p { font-family: $primaryfont; display: inline-block; text-align: center; margin: 0 auto; top: 12px; font-size: 18px; vertical-align: top; color: #575757; line-height: 16px; }
    }

    // .result-text { text-align: center; font-size: 18px; font-weight: 400; line-height: 28px; margin: 5px auto 15px; max-width: 48ch; background-color: #EEEEEE; padding: 25px 30px; border-radius: 10px; color: #121212; }
    .result-text { font-size: 18px; font-weight: 400; line-height: 28px; margin: 5px auto 15px; max-width: 777px; background-color: #EEEEEE; padding: 30px; border-radius: 10px; color: #121212; width: 95%; box-sizing: border-box; }
    .result-text p { padding-top: 0; margin-block-start: 0; margin: 10px auto; }

    // .score-section-container { & { grid-template-columns: repeat(5, 1fr);grid-template-rows: repeat(1, 1fr); display: grid; flex-direction: row; justify-content: center; align-items: center; max-width: 600px; padding: 1rem 0 1rem; margin: 0rem auto; width: 95%; }
    // .score-section-container { & { display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-evenly; align-items: center; padding: 1rem 0 1rem; margin: 0rem auto; width: 95%; }  
    // .score-section { 
    //     & { display: flex ; flex-direction: column; flex: 0 1 10%; width:33%; text-align: center; color: #343333; font-size: 18px; font-weight: 700; margin: 20px 10px; text-transform: uppercase; align-items: center; }
    //     .score-txt { font-size: 16px;line-height: 25px;font-weight:600; color: #343333; font-family: $primaryfont; margin: 6px auto; max-width: fit-content; transition: all 0.4s linear; }
    //     .score-name { text-align: center; font-weight:700; font-size: 18px; line-height: 22px; margin: 15px auto 10px; }
    //     .score-slider { width: 100%; margin: 0 auto; }
    //     .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; height: 6px; border-radius: 10px; width: 100%; }
    //     .custom-bar .MuiLinearProgress-bar { background-color: #FF9045; }
    //   }
    // }
    .score-section-container { & { display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-evenly; align-items: center; padding: 1rem 0 1rem; margin: 40px auto 0 auto; width: 95%; }
      .rowdata { & { display: flex; width: 100%; justify-content: center; align-items: center; color: #555555; } 
        .colnname { width: 28%; font-size: 16px; font-weight: 400; line-height: 21.6px; color: #555555; margin: 4px 0; text-transform: capitalize; }
        .colscore { & { width: 52%; height: 20px; background: transparent; display: flex; align-items: center; margin-left: 10px; }
          div { height: 100%; border-left: 2px; border-radius: 0px 10px 10px 0px; }
          h3 { margin: 0px 8px; font-size: 18px; font-weight: 400; }
        }
      }
    }

    .charts-accordions-container { & { width: 96%; max-width: 860px; margin: 15px auto 30px; box-sizing: border-box; padding: 10px 10px 30px; }
      .MuiAccordion-root { color: #FFFFFF; margin: 10px auto; }
      .MuiAccordionSummary-root { background-color: #100F2E; color: #FFFFFF; }
      .MuiAccordionDetails-root { 
        .question-info{ &{font-family: $fontLato; font-size: 18px; line-height: 31px; font-weight: 400; color: #100F2E; margin: 0;}
          .answer-details{&{background-color: #ffffff; padding: 15px; border-radius: 5px; margin: 28px auto;}
            .your-answer span{color: #F26722; font-weight: 700;}
            .answer-brief-detail{margin: auto; text-align: center; max-width: 60ch; margin: 20px auto;}
          }
          .border{width: 100%; height: 1px; background-color: #0000001A; margin-bottom: 28px;}
        }
      }
      .MuiAccordionSummary-content { & { color: #FFFFFF; font-size: 18px; line-height: 31px; margin: 5px auto; }
        p { margin: 0 auto 0 0; text-align: left; }
      }
      .MuiAccordionSummary-expandIconWrapper { color: #FFFFFF; }
      .accordion-chart-container { padding: 0px; box-sizing: border-box; margin: 15px auto; }
    }

  
  }

  .results .charts-accordions-container .MuiAccordionDetails-root { padding: 0; }
  .results .charts-accordions-container .accordion-chart-container { margin: 15px auto 0; }

  .dashboard-heading{ &{position: relative; display: flex; justify-content: center; align-items: center; flex-direction: column; } 
    p { color: #ffffff; text-align: center; font-size: 24px; font-weight: 600; }
    img { height: 30px; transform: rotate(90deg); margin: 10px 0; }
    .sample-heading { & { display: flex; justify-content: space-between; align-items: center; margin: 0 80px 15px; max-width: 1340px; width: 80%; }
    .left, .right { background-color: #ffffff; width: 35%; height: 2px; }
    .center { color: #ffffff; font-size: 36px; font-weight: 700; width: 19ch; text-align: center; }
    }
    .line{width: 96%; margin:auto; height: 3px; background-color: #00CDA7; margin: 80px auto 80px;}
    h1 { color: #FFF; text-align: center; font-family: $primaryfont; background-color: #0E435C; padding: 0 30px; position: absolute; top: -40px; left: 50%; transform: translateX(-50%); white-space: nowrap;}
  }
  .individual-group.sample { & { height: 50px; font-size: 21px; margin: 30px auto 2px; }
    button { display: flex; justify-content: center; align-items: center; }
  }
  .results-dashboard-container { & { width: 95%; max-width: 1340px; box-sizing: border-box; border-radius: 10px; box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.1); margin: 40px auto; }
    img { width: 100%; height: auto; border-radius: 10px;}
    .text-on-image { display: flex; position: relative; width: 48%; text-align: center; top: 342px; left: 25%; font-size: 18px; }
  }
  .accordions-section-container {
    h1 { font-size: 24px; font-family: $fontLato; font-weight: 700; font-size: 24px; line-height: 31px; color: #555555; }
    .toolkit-container { display: flex; justify-content: center; align-items: center; flex-direction: column;
      .toolkit-dashboard { display: flex; align-items: center; flex-direction: column; margin: 4px 0; border: 1px solid #004f88; width: 80%; border-radius: 17px;
        .toolkit-head { display: flex; justify-content: space-between; background-color: #004F88; color: #ffffff; width: 100%; height: 48px; box-sizing: border-box; border-radius: 15px 15px 0 0;  transition: border-radius 0.3s ease; border-bottom-left-radius: 15px; border-bottom-right-radius: 15px;
          p { margin: 0; line-height: 48px; font-weight: 700; font-size: 20px; padding-left: 15px; }
          &.closed { border-bottom-left-radius: 15px; border-bottom-right-radius: 15px; }
          &.opened { border-bottom-left-radius: 0; border-bottom-right-radius: 0; }
        }
        .toolkit-body { 
          & { width: 100%; overflow: hidden; border-bottom-left-radius: 15px; border-bottom-right-radius: 15px; }
          .toolkit-head.opened + & { border-bottom-left-radius: 15px; border-bottom-right-radius: 15px; }
          .toolkit-head.closed + & { border-bottom-left-radius: 0; border-bottom-right-radius: 0; }

        .toolkit-body:last-child.toolkit { border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; }
        .toolkit-data  { display: flex; justify-content: space-between; align-items: center; background-color: #D5EAF7; padding: 10px 15px; border-top  : 0.5px solid #004F88 ;
            p { margin: 0; color: #004F88; font-weight: bold; }
            button { background: none; border: none; cursor: pointer; padding: 5px;
              img { display: block; }
            }
          }
          .toolkit-data2  { 
            & { display: flex; justify-content: space-between; align-items: center; background-color: #FCFCFC; padding: 10px 15px; border-top  : 0.5px solid #004F88; border-bottom-left-radius: 15px; border-bottom-right-radius: 15px; }
            p { margin: 0; color: #004F88; font-weight: bold; }
            button { background: none; border: none; cursor: pointer; padding: 5px;
              img { display: block; }
            }
          }
          .verticalline { width: 1px; background-color: #004F88; }
          .toolkit-inner-head { & {display: flex; justify-content: space-evenly; background-color: #D5EAF7; } 
            h2 { line-height: 26px; font-size: 14px; font-weight: 700; color: #004F88; height: 26px; margin: 0 auto; width: 32%; text-align: center; }

          }
          .toolkit-inner-body-one, .toolkit-inner-body-three { background-color: #F5F5F5; } 
          .individual-tookit { & { max-height: 70px;  min-height: 70px; display: flex; justify-content: space-evenly; text-align: center; }
            .inner-one { font-weight: 700; color: #004F88; overflow-wrap: anywhere; width: 32%; display: flex; align-items: center; justify-content: center; }
            p { 
              & { width: 32%; font-size: 18px; font-weight: 400; display: flex; align-items: center; justify-content: center; }
              .none { padding: 6px 20px; border: 2px dotted #c8c3c3; border-radius: 10px; background-color:#FBF8F8; color: #555555; }
              .skipped { color: #55555566; }
            }
          }
          .content-details { background-color: white; padding: 20px; border-top: 1px solid #e0e0e0; position: relative; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; }
          .ministry-label { position: absolute; top: 10px; left: 20px; font-size: 14px; font-weight: bold; color: #004F88; }
          .avg-chart-container {
            & { display: flex; flex-direction: column; gap: 24px; }
            .avg-stats { & { display: flex; flex-direction: row; margin-right: 46px; width: 100%; color: #004F88; font-size: 16px; font-weight: bold; justify-content: center; align-items: center; gap: 25px; }
              .line { border-top: 0.5px solid #004F88; width: 100%; height: 0px; }
            }
          }
          .highcharts-credits { display: none; }
          .content-placeholder { margin-top: 40px; display: flex; flex-direction: column; align-items: center; }
          .content-placeholder > b { text-align: center; color: #555555; font-size: 18px; margin-bottom: 20px; font-weight: 700; font-family: $primaryfont; }
          .avg-percentage-container {
            & { width: 100%; }
            .statistics {
              & { width: 100%; display: flex; flex-direction: column; gap: 1rem; }
              .sec-container {
                & { display: flex; flex-wrap: wrap; color: #555555; font-family: $fontLato; font-size: 18px; font-weight: 400; line-height: 21.6px; text-align: right;  }
                .tools-name { width: 50%; padding-right: 18px; box-sizing: border-box; }
              }
              .per-bar {
                & { width: 50%; display: flex; gap: 10px; align-items: center; }
                .percent-bar {
                  & { height: 20px; border-top-right-radius: 5px; border-bottom-right-radius: 5px; }
                }
                .percentage { color: #FFFFFF; }
              }
              .stat-row {
                & { width: 100%; }
                .tool-name { text-align: right; }
              }
            }
            .sub-heading {
              & { display: flex; align-items: center; margin-bottom: 12px; }
              .label { font-family: $primaryfont; font-size: 16px; letter-spacing: 1px; line-height: 19px; color: #004F88; padding-right: 20px; text-align: center; white-space: nowrap; }
              .line { height: 0.5px; width: 100%; background-color: #004F88; }
            }
          }
          .usage-stats { width: 60%; padding: 10px; }
          .stat-row { display: flex; align-items: center; margin-bottom: 15px; font-size: 16px; }
          .tool-name { flex: 0 0 200px; margin-right: 10px; text-align: end; font-family: $primaryfont; font-size: 18px; font-weight: 400; line-height: 21px; color: #555555; }
          .percentage-bar { width: 200px; height: 38px; border-radius: 67px; border-top-left-radius: 20px; border-bottom-left-radius: 20px; overflow: hidden; position: relative; }
          .percentage-fill { height: 100%; border-top-right-radius: 12.5px; border-bottom-right-radius: 12.5px; position: absolute; left: 0; top: 0; }
          .percentage { position: absolute; right :10px;  top: 50%; transform: translateY(-50%); color: white; font-weight: bold; }
          .multiplier { margin-left: 10px; font-size: 16px; font-weight: bold; }
          .user-icon { width: 20px; height: 20px; margin-left: 5px; }
        }
      }
      .toolkit { & { display: flex; align-items: center; flex-direction: column; border: 1px solid #004f88; width: 75%; margin: 4px 0; border-radius: 17px; }
        .toolkit-head { & { display: flex; justify-content: space-between; background-color: #004F88; color: #ffffff; width: 100%; height: 48px; box-sizing: border-box; border-radius: 15px; cursor: pointer; }
          p { margin: 0; line-height: 48px; font-weight: 700; font-size: 20px; padding-left: 15px; }
        }
        .tool {
          & { display: flex; width: 100%; justify-content: space-between; }
          .toolkit-body { & { display: flex; justify-content: space-between; align-items: center; width: 90%; margin: 10px 5px; margin-left: -1px; text-align: center; flex-direction: column; }
          .alinecolon { display: flex; justify-content: center; align-items: center; flex-direction: column; }
          h3 { font-size: 14px; font-weight: 700; margin: 10px 0; color: #004f88; }
          p { font-size: 18px; font-weight: 400; color: #555555; margin: 10px 0px 10px 3px; text-align: center; }
          .verticalline { width: 1px; height: 74px; background-color: #004F88; }
          .directmail { 
            p { background-color: #fbf8f8; border: 2px dotted #E6E6E6; border-radius: 10px; width: 84px; height: 35px; text-align: center; line-height: 35px; }
          }
        }
        }
      }
    }
  }
} 

        
@media only screen and (max-width: 830px) {
  main .results .charts-accordions-container, main .dashboard .charts-accordions-container {
    padding: 0 0 45px;
  }
  main .accordions-section-container .toolkit-container .toolkit .tool{
    & { display: flex; width: 100%; justify-content: space-between; overflow-x: scroll; }
  }
  .dashboard-heading {
    h1 { font-size: 30px; top: -38px !important; }
  }
  main .results-dashboard-container .text-on-image { font-size: 12px; top: 215px !important; }

  // main .results .pervalue h4:after { top: 35px !important; font-size: 10px !important; right: -13px !important; }
  main .results .selection-form { & { font-size: 28px; }
    .form-details-text .MuiInput-root { font-size: 28px; }
  }
  .dashboard-heading{
    .line{margin: 80px auto 60px !important; height: 1px !important; background-color: #FFFFFF !important;}
  }
  .results-dashboard-container{ width: 95%; }
  main .results .score-section-container .rowdata .colnname { font-size: 9px; width: 35%; line-height: 1.2; }
  main .results .score-section-container .rowdata .colscore { height: 11px; width: 55%; }
  main .results .score-section-container .rowdata .colscore h3 { font-size: 10px; }
  // .results-dashboard-container {
  //   img { background-color: #FFFFFF; }
  // }
  
}



@media only screen and (max-width: 1568px) {
  main .dashboard-heading .sample-heading .left, main .dashboard-heading .sample-heading .right { width: 28%;  }

}
@media only screen and (max-width: 1065px) {
  main .results .score-section-container, main .dashboard .score-section-container {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
  main .dashboard-heading .sample-heading .center { font-size: 26px; }
  main .dashboard-heading .sample-heading .left, main .dashboard-heading .sample-heading .right { width: 24%; }
  .individual-tookit { max-height: 80px;  min-height: 80px; }
}


@media only screen and (max-width: 650px) {
  main .results {
    .selection-form .form-details-text { background-color: #FFF; }
    .down-arrow { height: 18px; right: 0; }
  }
  main .dashboard-heading {
    h1 { font-size: 24px; top: -30px !important; padding: 0 20px !important;}
    .sample-heading .left, .sample-heading .right { width: 24%; }
    .sample-heading { width: 95%; }
    .sample-heading .center { font-size: 18px; }
  }
  main .results{
    padding: 0px 0;
  }
  .MuiAccordionDetails-root { 
    .question-info{ 
      .answer-details{padding: 10px;}
    }
  }
  main .accordions-section-container .toolkit-container .toolkit .toolkit-head { align-items: center;
    .MuiButton-root img { height: 21px; width: 21px; }
    p { font-family: $primaryfont; font-size: 16px; font-weight: 700; line-height: 19.2px; text-align: left; }

  }
  main .accordions-section-container {  margin: 0px auto !important; padding: 10px 10px !important; }
  main  .invite-section-container  { margin: 20px auto; padding: 20px; }
  main .results { min-height: 400px; margin: 30px auto 10px !important; }
  main .results .score-section-container { margin: 0px auto; padding: 15px 0px 35px; }
  main .accordions-section-container .toolkit-container .toolkit { width: 100%; }  
  main .accordions-section-container h1 { font-size: 18px; }
  main .accordions-section-container .toolkit-container .toolkit .toolkit-body { width: 98%; margin: 10px 4px; }
  main .accordions-section-container .toolkit-container .toolkit-dashboard .toolkit-body .toolkit-inner-head h2 { line-height: 31px; height: 31px; font-size: 10px; }
  main .accordions-section-container .toolkit-container .toolkit-dashboard .toolkit-body .individual-tookit p { font-size: 12px; }
  main .accordions-section-container .toolkit-container .toolkit-dashboard .toolkit-head p { font-size: 16px; }
  main .accordions-section-container .toolkit-container .toolkit-dashboard .toolkit-body .ministry-label { font-size: 16px; }
  main .accordions-section-container .toolkit-container .toolkit-dashboard .toolkit-body .content-placeholder > b { font-size: 16px; }
  main .accordions-section-container .toolkit-container .toolkit-dashboard .toolkit-body .tool-name { flex: 0 0 65px;  margin-right: 2px; }
  main .accordions-section-container .toolkit-container .toolkit-dashboard .toolkit-body .usage-stats { width: 80%;}
  main .results .score-section-container .rowdata .colscore div { border-radius: 0px 5px 5px 0px; }
  main .accordions-section-container .toolkit-container .toolkit-dashboard .toolkit-body .avg-percentage-container .statistics { gap: 0; }
}

@media only screen and (max-width: 550px) {

  main .results { width: 95%; margin-top: 15px; }
  main .results .selection-form { width: max-content; max-width: 95%; flex-wrap: wrap; }
  main .results .selection-form .form-detail { width: 100%; margin-right: 0; }
  main .results .selection-form .form-text { width: 100%; margin-bottom: 10px; text-align: center; font-size: 18px; margin-top: 8px; }
  main .results h1 { font-size: 21px; padding: 1.5rem 1rem 1rem; width: auto; line-height: 28px; }
  main {
    .results .result-text, .dashboard .result-text { & { margin: 5px auto 20px; font-size: 18px; }
      p { margin: 6px auto; }
    }
  }
  main .results .result-sections { margin: 50px 5%; padding-bottom: 50px; }
  main .results .result-sections .score-section { flex: 0 1 100%; margin-bottom: 20px; }
  main  .cta-btn { & { width: 100%; max-width: 90%; } }
  main .results .result-sections .score-section .score-txt { margin-bottom: 9px; }
  main .results .progress-bar { & { width: 100%; }
    
  }
  main .individual-group.sample { height: 30px; font-size: 18px; width: 272px; margin: 30px auto 0; }
  main .results-dashboard-container { margin: 40px auto; }
  main .results .si_ass_progress .score-arrow { bottom: 32px; }
  main .results .si_ass_progress .msi_score { font-size: 16px; padding: 4px 2px; bottom: 35px; margin-left: -25px; }
  main .results .members-answered { width: 100%; font-size: 16px; margin: 7px auto; }
  main .results .members-answered .answered-detail { 
    & { font-size: 14px; padding: 10px 10px; width: 100%; box-sizing: border-box; }
    .answer { min-width: 9ch; max-width: 12ch; font-size: 12px; }
  }
  main .results .score-section-container { & { grid-template-columns: repeat(1, 1fr);grid-template-rows: repeat(1, 1fr); }
    .score-section { width: 100%; max-width: 90%; flex: 0 1 65%; }
  }
  
  .si_ass_progress { height: 15px !important; }
  main .results .progress-bar {
    & { max-width: 95%; margin: 60px 0 5px; position: relative; }
  }
  main .results .pre-score-header { font-size: 18px; margin: -20px 0 20px; }
  main .results .pervalue h4:after { top: 35px !important; font-size: 10px !important; right: -13px !important; }
  main .accordions-section-container .toolkit-container .toolkit .tool .toolkit-head p { font-size: 16px; }
  main .accordions-section-container .toolkit-container .toolkit .tool .toolkit-body h3 { font-size: 12px; }
  main .accordions-section-container .toolkit-container .toolkit .tool .toolkit-body p  { font-size: 16px ; }
  main .accordions-section-container .toolkit-container .toolkit-dashboard { width: 100%; }


  .usage-stats { width: 100%; padding: 5px; }
  main .accordions-section-container .toolkit-container .toolkit-dashboard .toolkit-body .usage-stats { width: 100%; }
  main .accordions-section-container .toolkit-container .toolkit-dashboard .toolkit-body .tool-name { font-size: 14px; }
  main .accordions-section-container .toolkit-container .toolkit-dashboard .toolkit-body .percentage { right: 10px; }
  main .accordions-section-container .toolkit-container .toolkit-dashboard .toolkit-body .percentage-bar { width: 100%; margin: 0 10px; height: 27px; }
    .multiplier { margin-left: 5px; }
    .user-icon { margin-left: 2px;}
    main .accordions-section-container .toolkit-container .toolkit .tool { flex-direction: column; }
    main .accordions-section-container .toolkit-container .toolkit .tool .toolkit-body { width: 95%; }
    main .accordions-section-container .toolkit-container .toolkit-dashboard .toolkit-body .tool-name { flex: 0 0 100px;  margin-right: 2px; }
}

@media only screen and (max-width: 500px) {
  .dashboard-heading {
    h1 { font-size: 20px; top: -25px !important; padding: 0 20px !important; }
  }
  .sample-heading .center { font-size: 16px; }
  .si_ass_result { height:  95px; }
}
