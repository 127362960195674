main {
  .container { margin: 45px auto 80px; width: 95%; max-width: 1344px; border-radius: 10px; z-index: 1; } 
  .start.container { display: flex; margin: 45px auto 120px; width: 95%; max-width: 1344px; border-radius: 10px; z-index: 1; } 
  // .start { background: #0E435C; }
  .loading { display: flex; justify-content: center; align-items: center; }
  // .container.start { width: 95%; }
  // .container.start { width: 95%; background-color: #0E435C; background-size: cover; background-repeat: no-repeat; background-position: center; border-radius: 10px; overflow: hidden; }
  .start {
    .video {
      // & { padding: 0.5rem; border-radius: 8px 0 0 8px; position: relative; width: auto; height: 100%; display: flex; justify-content: center; object-fit: cover; transition: all 0.25s; overflow: hidden; }
      // video { margin: 0 auto; }
      & { width: 50%;
        height: auto; max-height: 617px; display: flex; justify-content: center; position: relative; border-radius: 10px 0 0 10px; background-color: #1D1D1D; object-fit: cover; transition: all 0.25s; overflow: hidden; box-sizing: border-box; }
      video { margin: 0px auto; position: relative;  }
      .custom-controls { 
        & { align-items: center; background-color: rgba(0, 0, 0, .5); border-radius: 30px; bottom: 10px; box-sizing: border-box; display: flex; gap: 10px; justify-content: space-between; left: 50%; max-height: 45px; max-width: 530px; padding: 5px 15px; position: absolute; top: 86%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%); width: 100%; z-index: 2; }
        .custom-slider { -webkit-appearance: none; height: 2px; width: 70%; border-radius: 50%; background: #ddd; }
        .custom-slider::-webkit-slider-thumb { -webkit-appearance: none; height: 20px; width: 20px; border-radius: 50%; background: #99BB00; cursor: pointer; }
        .custom-slider::-moz-range-thumb {  height: 20px; width: 20px; border-radius: 50%; background: #99BB00; cursor: pointer; }
        .custom-slider::-ms-thumb { height: 20px; width: 20px; border-radius: 50%; background: #99BB00; cursor: pointer; }
        
        button { display: flex; align-items: center; justify-content: center; align-self: center;background-color: transparent; border: none; color: #fff; cursor: pointer; padding: 5px; margin: 2px 0 0; }
        span { color: #fff; font-size: 14px; min-width: 70px; text-align: left; }
      } 
      .thumbnail { position: absolute; height: 100%; width: 100%; object-fit: cover; overflow: hidden; background-color: #E5E5E5; }
      .playbtn { position: absolute; bottom: 10%; left: 50%; transform: translate(-50%, -50%); cursor: pointer; height: 45px; width: 223px; }
      .playbtn.leader { top: 82%; }
      // .playbtn { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); cursor: pointer; height: 144px; width: 144px; }
      
    }
    .intro {
      & { background-color: #FFFFFFF2; box-sizing: border-box; width: 50%; padding-left : 1%; display: flex; flex-direction: column; justify-content: center; transition: all 0.25s; align-items: start; text-align: center; max-width: 1108px; margin: 0px auto; border-radius: 0 10px 10px 0; }
      // h1 { color: #343333; font-family:$primaryfont; font-size: 32px; font-style: normal; font-weight: 700; line-height: 40px; margin: 0px; padding-bottom: 30px; max-width: 45ch; }
      h1 { color: #004F88; font-family:$primaryfont; font-size: 37px; font-weight: 700; padding-left: 25px; line-height: 54px; margin: 0px; max-width: 45ch; text-align: left; }
      p { font-size: 18px; font-family: $primaryfont; padding-left: 25px; font-weight: 700; line-height: 29px; color: #ED2028; }
      .intro-para { & { max-width: 706px; }
        p { font-weight: 300; font-size: 21px; line-height: 30px; color: #100F2E; text-align: center;  font-family: $fontLato; margin: 0px auto;  }
      }
      h2 { margin: 0; margin-bottom: 0; font-weight: 300; font-style: normal; color: $color3; font-size: 24px; line-height: 27px; max-width: 100%; max-width: 27ch; font-family: $fontLato; }
      .intro-points-list {
        & { font-weight: 400; font-style: normal; color: #343333; font-size: 21px; line-height: 36px; padding-left: 45px; margin: 20px 0 0; }
        // li { color: #343333; font-family: $primaryfont; font-size: 24px; font-style: normal; font-weight: 300; line-height: 35px; text-align: start;  }
        li { color: #555555; font-family: $primaryfont; font-size: 21px; font-style: normal; font-weight: 400; line-height: 36px; text-align: start; margin-bottom: 20px; }
      }
      .completion {
        & { display: flex; flex-direction: row; text-align: center; justify-content: flex-start; gap: 10px; margin: 0px auto 15px; color: #343333; font-weight: 400; font-size: 18px; width: 100%; align-items: center; padding-left: 20px; }
        // .cta { display: block; background-color: #ED2028; border-radius: 6px; color: #fff; text-decoration: none; width: 100%; max-width: 200px; height: 50px; text-align: center; font-family: $primaryfont; font-size: 21px; font-weight: 700; text-transform: uppercase; padding: 7px 12px; margin-left: 0; transition: 0.3s; letter-spacing: 1px; }
        .cta { display: block; background-color: #83bd2e; border-radius: 25px; color: #fff; text-decoration: none; width: 100%; max-width: 205px; height: 50px; text-align: center; font-family: $primaryfont; font-size: 21px; font-weight: 700; text-transform: capitalize; padding: 7px 12px; margin-left: 0; transition: 0.3s; letter-spacing: 1px; }
        .cta:hover { background-color: #4C5454; transition: 0.3s; color: #fff; }
        .time-estimate {
          & { display: flex; justify-content: center; margin: 15px 25px; color: #100F2E; }
          .clock-icon { width: 20px; height: 20px; margin-top: 3px; padding-right: 6px; }
          span { font-size: 21px; font-family: $primaryfont; font-weight: 400; color: #555555; }
        }
      }
      .para-container {
        & { width: auto; text-align: left;  }
        .para { font-size: 18px; font-weight: 300; line-height: 24px; color: #555555; padding: 5px 0px; font-family: $primaryfont; margin: 0px; }
      }
    }
    // .para-container{
    //   p{
    //     color: #5A5A5A;
    //     font-size: 24px;
    //     font-weight: 400;
    //     margin: 0.5rem;
    //     text-align: center;
    //   }
    // }
    //   .completion { 
    //     & { display: flex; flex-direction: row; align-items: center; color: #4e4229; font-weight: 400; font-size: 18px; padding: 1.5rem 0rem; }
    //   }
    //   .btn-container{ & { padding-bottom: 2rem; }
    //   .cta { display: block; background: #348CFE; border-radius: 25px; color: #fff; text-decoration: none; width: 193px; text-align: center; font-family: $primaryfont; font-size: 20px; font-weight: 700; padding: 12px 0 12px; margin-left: 0; }
    //     .cta:hover { background: #fcb900; transition: 0.3s; color: #fff; }
    // }
    //     .time-estimate {
    //       & { display: flex; margin: 0; background-color: #FDE6A2; padding: 0.3rem 0.75rem 0.3rem 0.75rem; border-radius: 30px; }
    //       .clock-icon { width: 22px; height: 22px; }
    //       .img-container { padding-top: 0.1rem; padding-right: 0.25rem ; padding-left:0.5rem; }
    //     }
  }
  
}

@media only screen and (max-width:1350px) {
  main .start .video .playbtn { bottom: 6%; }
}

@media only screen and (max-width:1110px) {

  main .start .intro {
    & { padding: 30px 30px; }
    h1 { font-size: 30px; line-height: 34px; }
    .intro-points-list {
      & { font-size: 18px; }
      li { line-height: 30px; }
    }
    .completion { margin-top: 15px; }
  }
  main .start .video .playbtn { bottom: 11%; }
}

@media only screen and (max-width:1020px) {
  main .start .video .playbtn { bottom: 6.5%; }
}

@media only screen and (max-width:950px) {
  main .start .intro { padding: 30px 10px; }
}

@media only screen and (max-width:865px) {
  main .start .intro { padding: 30px 5px; }
  main .start .intro .intro-points-list li { line-height: 28px; }
}

@media only screen and (max-width:550px) {

  main .completion .cta { margin: 0 auto !important; width: 90%; }
}
// @media only screen and (min-width:831px) {
//   // main .bleat-logo-mobile { display: none; }
//   // main .container.start { margin: 30px auto; }
// }


@media only screen and (max-width: 830px) {

  main {
    // .container.start { margin: 15px auto; }
    .start {
      .intro {
        & { width: 95%; box-sizing: border-box; margin: 0 auto; height: auto; border-radius: 0 0 10px 10px; }
        h1 { text-align: center; font-size: 24px; margin: 0 auto 15px; padding-top: 30px; font-weight: 700; }
        h2 { text-align: center; width: 100%; max-width: 34ch; margin: auto; }
        h1 br { content: ""; }
        h1 br:after { content: " "; }
        .intro-para {
          & { max-width: 35ch; margin: 5px auto; }
          p{ text-align: center; font-weight: 400; font-size: 16px; line-height: 31px; }
        }
        .intro-points-list { width: auto; margin: 10px auto; padding-left: 0px; text-align: start; padding: 15px 30px; }
        .completion {
          & { flex-direction: column; margin: 30px auto 0 auto; }
          .time-estimate { margin: 15px auto; }
          .cta { margin-bottom: 0; align-self: center; }
        }
      }
    }
    .start {
      // & { flex-direction: column; height: auto; width: 95%; }
      .video {
        & { width: 100%; max-width: 95%; box-sizing: border-box; height: auto; border-radius: 10px 10px 0 0; min-height: 400px; margin: 0px auto; }
        // .playbtn { top: 50%; }
      }
    }
    .results .result-text, .dashboard .result-text { width: 95%; box-sizing: border-box; font-size: 20px; }
    .thank-you {
      h1 { font-size: 18px; line-height: 30px; }
      p { font-size: 16px; }
    }
  }
  main .logo-left-container { display: none; }
  main .start .video video { width: 100%; height: auto; background-color: #000000; left: 0; }
  //margin: 0 -10%; object-fit: cover; }
  main .start .video .thumbnail { width: auto; height: 100%; }
  main .start .intro .para-container {
    & { text-align: center; width: 100%; }
    .para { font-size: 14px; line-height: 18px; padding: 5px 15px 15px; max-width: 100%; }
  }
  main .start .intro .completion { padding-left: 0px; }
}
@media only screen and (max-width:650px) {

  main .start {
    .video .playbtn { width: 220px; height: auto; bottom: 10%; }
    .intro {
      // h1 { text-align: center; }
      // h2 { text-align: center; font-size: 1.25rem; line-height: 25px; }
      h1 br { content: ""; }
      h1 br:after { content: " "; }
    }
    .para-container p { font-size: 18px; }
  }
}
@media only screen and (max-width: 550px) {

  main .start.container { margin: 20px auto; background-color: #FFFFFF; }

  // main .start { margin-top: 15px; }
  main .start .video {
    & { height: 50vw; height: auto; width: 100%; border-top-left-radius: 10px; border-top-right-radius: 10px; border-bottom-left-radius: 0; border-bottom-right-radius: 0; }
    .close-video { right: 5px !important; top: 5px !important; }
  }
  // main .start .video{
  //   & { border-radius: 5px; height: 325px; width: 100%; }
  //   .thumbnail { 
  //     & { object-fit: fill;  height: 100%; width: 80%; border-radius: 20px;}
  //   }
  // }  
  main .start {
    .video {
      & { border-radius: 5px 5px 0 0; min-height: 400px; height: auto; width: 100%; max-width: 100%; }
      .thumbnail {
        & { object-fit: cover; width: auto; height: 100%; }
      }
    }
    .intro {
      & { border-radius: 0 0 10px 10px; width: 100%; }
      h1 { font-size: 20px; line-height: 25px;  margin: 0px auto; font-weight: 700; padding: 0; }
      h2 { font-size: 17px; max-width: 34ch; }
      .intro-para { font-size: 17px; line-height: 20px; max-width: 28ch; }
      .intro-points-list { 
        & { box-sizing: border-box; width: 95%; margin: auto; padding: 30px 5px 15px 25px; }
        li { font-size: 14px; font-weight: 300; line-height: 27px; max-width: 32ch; margin: 0 auto; }
      }
      // .completion {
      //   .cta { font-size: 20px; font-weight: 700; max-width: 170px; }
      //   .time-estimate {
      //     & { align-items: center; }
      //     span { font-size: 15px; font-style: normal; font-weight: 400; line-height: 35px; }
      //   }
      // }
      .completion {
        & { flex-direction: column; margin: 30px auto 0 auto; }
        .time-estimate { margin: 5px auto 15px; }
        .cta { margin-bottom: 0; align-self: center; }
      }
      img { margin: 0 4px 0 0; }
    }
  }

  main .start .intro .para-container .para { max-width: 46ch; }
  main .start .video video { object-fit: cover; }

}


@media only screen and (max-width: 500px) {

  main .start {
    .video .playbtn { width: 220px; height: auto; }
  }
}


